.btn-primary {
  background-color: #ea0029 !important;
}

.btn-info {
  background-color: #0075c9 !important;
}

.bg-info {
  background-color: #0075c9 !important;
}

.sidebarArea .sidebarBox.bg-white .activeLink .nav-link {
  color: #ea0029 !important;
  border-left: 3px solid #ea0029 !important;
}

.pagination-div {
  float: right;
}

.val-error {
  color: #ea0029;
}

.icon-delete {
  color: #ea0029;
}

.icon-edit {
  color: #0075c9;
}

.icon-view {
  color: #38d430;
}

.actions {
  display: flex;
  justify-content: space-evenly;
}

.react-datepicker-wrapper {
  display: block !important;
}

.input-direction {
  direction: rtl;
}

.form-check-input:checked{
 background-color:#54B4D3 !important;
 border-color: #54B4D3 !important;
}